<template>
  <div class="my-10">
    <v-card class="mx-auto pa-4" height="300" width="400">
      <h2 class="primary white--text text-center mb-5">ADMIN LOGIN</h2>

      <v-text-field
        v-model="dform.username"
        prepend-inner-icon="mdi-account-circle"
        label="Your Username"
        :rules="messageRules"
        outlined
        dense
      >
      </v-text-field>

      <v-text-field
        v-model="dform.passwd"
        prepend-inner-icon="mdi-lock"
        label="Password"
        outlined
        dense
        :rules="messageRules"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
      >
      </v-text-field>
      <v-row justify="center" class="mt-3">
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="!isValid"
          class="mb-2"
          @click="processLogin"
        >
          Login
        </v-btn>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { snackMixin, rulesMixin } from "@/mixins";
import { apiClient } from "@/services";
import { mapActions, mapState } from "vuex";
export default {
  name: "AdminLogin",
  mixins: [snackMixin, rulesMixin],

  data() {
    return {
      showPassword: false,
      dform: {
        username: "",
        passwd: "",
      },
    };
  },

  computed: {
    ...mapState(["login"]),
    isValid() {
      const reqf = ["username", "passwd"];
      return reqf.every((v) => !!this.dform[v]);
    },
  },

  methods: {
    ...mapActions(["setLogin", "setCurUser"]),
    processLogin() {
      apiClient
        .post("login", this.dform)
        .then((res) => {
          if (res.data.id) {
            this.setCurUser({ admin: res.data });
            this.setLogin(true);
            this.$router.push({
              name: "Dashboard",
            });
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((error) => this.displayMsg(error.message, "error"));
    },
  },
};
</script>
